import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {GlobalStateContext} from "../context/GlobalContextProvider";

function    CmCheckBiz({title='SESAME Biz 更新に伴うプラン更新お手続きのお知らせ',
                           context='SESAME Bizの新しいバージョンではプランのルールが変更されているため、[更新] をクリックすると以下の新しいプランが適用されます。',
                           cancel='キャンセル',sure='更新',callSure,setCustomModalOpen}) {

    const {

        gStripe

    } = useContext(GlobalStateContext)
   const [ levelText,setLevelText]=useState('Free')
   const [ levelPrice,setLevelPrice]=useState('0')
   const [ levelPriceTaxIn,setLevelPriceTaxIn]=useState('0')
   const [ levelUser,setLevelUser]=useState('5')
   const [ levelDevice,setLevelDevice]=useState('1')
   const [ levelApi,setLevelApi]=useState('3,000回')
    useEffect(()=>{

        console.log("当前配置",gStripe.levelInfo)

        if (gStripe.levelInfo.isToNewLevel){
             if (gStripe.levelInfo.isToNewLevel===2){
                 setLevelText('Light')
                 setLevelPrice("2,178")
                 setLevelPriceTaxIn("2,396")
                 setLevelUser("20")
                 setLevelDevice("2")
                 setLevelApi("30,000回")

             }else if (gStripe.levelInfo.isToNewLevel===4){
                 setLevelText('Pro')
                 setLevelPrice("5,478")
                 setLevelPriceTaxIn("6,026")
                 setLevelUser("50")
                 setLevelDevice("5")
                 setLevelApi("100,000回")

             }else if (gStripe.levelInfo.isToNewLevel===6){
                 setLevelText('Business')
                 setLevelPrice("10,780")
                 setLevelPriceTaxIn("11,858")
                 setLevelUser("100")
                 setLevelDevice("10")
                 setLevelApi("500,000回")

             }else if (gStripe.levelInfo.isToNewLevel===8){
                 setLevelText('Enterprise')
                 setLevelPrice("21,780")
                 setLevelPriceTaxIn("23,958")
                 setLevelUser("200")
                 setLevelDevice("50")
                 setLevelApi("無制限")
             }
        }

    },[gStripe.levelInfo])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // 在主轴方向上分布空间
        padding: 2, // 内边距
      }}
    >
      {/* 标题 */}
      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontWeight: "600",
          fontFamily: "'Noto Sans JP', sans-serif",
        }}
      >
          {title}
      </Typography>

      {/* 内容区域 */}
      <Box
        sx={{
          overflow: 'auto', // 如果内容过多，显示滚动条
          paddingTop: "20px",
          paddingBottom: "30px"
        }}
      >
          {context}
      </Box>
        <Typography
          sx={{ 
            fontSize: "18px",
            fontWeight: "600",
            fontFamily: "'Noto Sans JP', sans-serif",
            paddingBottom: "8px"
          }}
        >
            {`新しいプラン：${levelText}`}
        </Typography>
        
        <Typography
          sx={{ 
            fontSize: "18px",
            fontWeight: "600",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        >
            {`毎月のお支払い：${levelPrice}円`}
            <span style={{ color: "rgb(221 221 221)"  }}>{`（税込 ${levelPriceTaxIn}円）`}</span>
        </Typography>
        <Typography
          sx={{ 
            fontSize: "14px",
            color: "#86868b",
            fontFamily: "'Noto Sans JP', sans-serif",
            paddingBottom: "8px"
          }}
        >
            ※上記は月払いプランです。初回更新の際は月払いプランに設定されます。
        </Typography>
        <Typography
          sx={{ 
            fontSize: "18px",
            fontWeight: "600",
            fontFamily: "'Noto Sans JP', sans-serif",
            paddingBottom: "8px"
          }}
        >
            {`ご登録可能ユーザー数：${levelUser}人`}
        </Typography>
        <Typography
          sx={{ 
            fontSize: "18px",
            fontWeight: "600",
            fontFamily: "'Noto Sans JP', sans-serif",
            paddingBottom: "8px"
          }}
        >
            {`ご登録可能デバイス数：${levelDevice}台`}
        </Typography>
        <Typography
          sx={{ 
            fontSize: "18px",
            fontWeight: "600",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        >
            {`APIリクエスト上限：${levelApi}`}
        </Typography>
        <Typography
          sx={{ 
            fontSize: "16px",
            color: "#86868b",
            fontWeight: "600",
            fontFamily: "'Noto Sans JP', sans-serif",
            paddingTop: "30px"
          }}
        >
          新しいプラン全体のご紹介は
          <a 
            href="https://jp.candyhouse.co/pages/sesame-biz-operation#biz_plans" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ 
              textDecoration: 'underline', 
              color: '#86868b', 
              display: 'inline' }}
          >
            こちら
          </a>
        </Typography>

        {/* 按钮区域 */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // 按钮靠右显示
          gap: 1, // 按钮之间的间隔
          marginTop: "20px"
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "#28aeb1",
            borderColor: "transparent",
            "&:hover": {
              borderColor: "transparent",
              opacity: "0.8",
            }
          }}
          onClick={()=>{
            setCustomModalOpen(false)
          }}
        >
          {cancel}
        </Button>
        <Button
          variant="contained" 
          disableElevation
          sx={{
            backgroundColor: "#28aeb1",
            "&:hover": {
              backgroundColor: "#28aeb1",
              opacity: "0.8",
            },
          }}
          onClick={()=>{
              setCustomModalOpen(false)
              if (callSure) {
                  callSure()
              }
          }}>
            {sure}
          </Button>
      </Box>
    </Box>
  );
}

export default CmCheckBiz;
