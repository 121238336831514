import React, {useContext, useEffect, useState} from "react"
import { Typography, Stack, Box } from "@mui/material"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import Layout from "../layouts"
import { Link } from "gatsby"
import CmCheckBiz from "../components/cmcheckbiz";

const Index = ({ location }) => {
  const { gCompanyEmployee,  gStripe,gCfp ,setModalContent,setCustomModalOpen} =
    useContext(GlobalStateContext)


   const [memberCount,setMemberCount]=useState('')
   const [lockCount,setLockCount]=useState('')

     useEffect(()=>{

         console.log("打印数据测试",gStripe)

         if (!gStripe.customer.isRootUser){
             if (!gStripe.isNewBiz){
                 showOldBiz()

             }
         }


     },[gStripe.isNewBiz])


 const noLimit='/無制限'

    useEffect(()=>{
       // console.log("当前lelveinfo",gStripe.levelInfo)

        if (gStripe.levelInfo){

            setMemberCount(`/${gStripe.levelInfo.memberCount}人`)
            setLockCount(`/${gStripe.levelInfo.lockCount}台`)

        }
        if (gStripe.customer.isRootUser){
            setMemberCount(noLimit)
            setLockCount(noLimit)
        }

    },[gStripe.levelInfo,gStripe.customer])

    const showOldBiz = async () => {

        setModalContent(
            <CmCheckBiz

                setCustomModalOpen={setCustomModalOpen}
                callSure={()=>{

                    gStripe.getApiLevelInfo(undefined,true)
                }}

            />
        )
        setCustomModalOpen(true)

    };


  return (
    <Layout location={location}>
      <Typography variant="h3" sx={{ m: "24px 24px 0px 24px" }}>
        SESAME Bizへようこそ、現在のご使用状況です。
      </Typography>
      {gCompanyEmployee.employees.count !== 0 && (
        <Stack direction="column" spacing={4} sx={{ p: "18px" }}>
          <Stack direction="row" spacing={2}>
            <Box
              component={Link}
              to={"/employees"}
              sx={{
                width: "220px",
                borderRadius: "6px",
                backgroundColor: "secondary.light",
                textAlign: "left",
                color: "inherit",
                cursor: "pointer",
                p: "10px",
                "&:hover": {
                  opacity: "0.6",
                },
              }}
            >
              <Typography>ユーザー数</Typography>
              <Typography
                style={{
                  fontSize: "60px",
                  fontWeight: "bold",
                }}
              >
                {gCompanyEmployee.employees.count}
                <span style={{ fontSize: "20px", fontWeight: "normal" }}>
                  {memberCount}
                </span>
              </Typography>
            </Box>
            <Box
              component={Link}
              to={"/devices"}
              sx={{
                width: "220px",
                borderRadius: "6px",
                backgroundColor: "secondary.light",
                textAlign: "left",
                color: "inherit",
                cursor: "pointer",
                p: "10px",
                "&:hover": {
                  opacity: "0.6",
                },
              }}
            >
              <Typography>ドア数</Typography>
              <Typography
                style={{
                  fontSize: "60px",
                  fontWeight: "bold",
                }}
              >

                {gCfp.cfpSsmDevices.length}
                <span style={{ fontSize: "20px", fontWeight: "normal" }}>
                    {lockCount}
                </span>
              </Typography>
            </Box>
            <Box
              component={Link}
              to={"/cfp"}
              sx={{
                width: "220px",
                borderRadius: "6px",
                backgroundColor: "secondary.light",
                textAlign: "left",
                color: "inherit",
                cursor: "pointer",
                p: "10px",
                "&:hover": {
                  opacity: "0.6",
                },
              }}
            >
              <Typography>認証機器数</Typography>
              <Typography
                style={{
                  fontSize: "60px",
                  fontWeight: "bold",
                }}
              >
                {gCfp.cfpDevices.length}
                {/* {gCfp.getCfpDevices.length} */}
                <span style={{ fontSize: "20px", fontWeight: "normal" }}>
                  台
                </span>
              </Typography>
            </Box>
          </Stack>
        </Stack>
      )}
    </Layout>
  )
}

export default Index
